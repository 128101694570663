
import CKEditor from '@/components/CKEditor.vue'
import { SET_CONTENT_REQUEST } from '@/store/actions/website_content.ts'

export default {
    name: 'FAQ',
    data() {
        return {
            content_name: 'faq'
        }
    },
    components: {
        CKEditor,
    },
    methods: {
        get_content: function() {
            return this.$store.state.website_content.content[this.content_name]
        },
        set_content: function(new_content) {
            this.$store.dispatch(SET_CONTENT_REQUEST, {name: this.content_name, content: new_content}).then()
        },
        scrollTo() {
            var el = this.$refs['faq']
            var position = el.offsetTop

            window.scrollTo(0, position)
        }
    },
    mounted: function() {
        //this.scrollTo()
    },
}
